import React from 'react'
import { Flex, Box } from '@theme-ui/components'
import Header from './Header/Header'
import FooterSelector from './Footer/FooterSelector'
import CTAWidgetSelector from './CTAWidget/CTAWidgetSelector'

export default function Layout(props) {
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'column', 'column', 'row'],
        width: '100%',
      }}>
      <Box
        sx={{
          width: ['100%', '100%', '100%', '20%'],
          // maxWidth: ['100%', '100%', '100%', '275px'],
        }}>
        <Header {...props} />
      </Box>
      <Box
        sx={{
          width: ['100%', '100%', '100%', '80%'],
          flexGrow: ['', '', '', '0'],
        }}>
        {props.children}
        <CTAWidgetSelector {...props} />
        <FooterSelector {...props} />
      </Box>
    </Flex>
  )
}

// List of props here
// {
//   children,
//   configData,
//   businessData,
//   navLinks,
//   locationIndex,
//   city,
//   locations,
//   gonationID,
//   isMultipleLocationSite
// }
