// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================

import React, { useEffect, useState, useContext } from 'react';
import { graphql } from 'gatsby';
import { useThemeUI, Flex, Box } from 'theme-ui';
import shortid from 'shortid';

// ?===================================
// ?===  Local Components Imports  ====
// ?===================================

import SideNavLayout from '../components/Layout/SideNavLayout';
import SEO from '../components/Layout/seo';
import { store } from '../Context/Store';
import ContactFormsContainer from '../components/ContactForm/ContactFormsContainer';
import OpeningPage from '../components/OpeningPages/OpeningPageV1';

// ?===================================
// ?====  Local Function Imports  =====
// ?===================================
import checkForCustomLinks from '../helpers/checkForCustomLinks';
import importPageComponents from '../helpers/importPageComponents';
import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter';

// ?===================================
// ?========  Style Imports  ==========
// ?===================================
import '../../src/index.css';
import 'react-image-lightbox/style.css';

// This Template is used on every generated page on the site. apart from the multi location Landing page.
// its job is to get the data from the page context and graphql query and then funnel it to the right locations.
// data has to passed into two places. The Layout(i.e navigation and footer.) and the dyanmic component renderer
// this componenet also handle a dynamic theme color. passing the data into theme UI.

// ?===================================
// ?======  Template Component  =======
// ?===================================

const SideNavPageTemplateV2 = (props) => {
  // ?===========================
  // ?===== Component State =====
  // ?===========================

  const { globalState, dispatch } = useContext(store);
  const [components, setComponents] = useState(null); // where the pages componenets are stored after dynamically importing

  // ?========================
  // ?===== Destructing  =====
  // ?========================

  const { pageContext, data, location } = props;
  const { businessData, poweredImages, allConfigData } = data;
  const configData = allConfigData.edges?.[0].node;
  const allGeneral = data.allGeneral.edges.map(({ node }) => node); // removes node layer
  const { metaTitle, metaDescription, keywords } = configData;
  const { name, desc, city, avatar, tags } = businessData;

  const {
    pageObjectKey,
    pageComponents,
    customPageName,
    locations,
    // gonationID,
    // locationIndex,
    // city,
    isAdditionalPage,
    // isMultiLocationSite,
  } = pageContext;

  // ?==============================
  // ?=====  Variable Creation  ====
  // ?==============================

  // grabs the theme ui object for use
  const themeContext = useThemeUI().theme;

  // this determines the page title used in the navigation.
  // if its custom page name use it. if not use the pageobject keywhich is the default name
  const pageTitle = `${customPageName !== '' ? customPageName : pageObjectKey}`;
  const isMultiLocationSite = locations.length > 1;

  // ?=============================================
  // ?==============   Functions   ================
  // ?=============================================

  // this gets the components for this page and dyanmically renders them and gives them all the props possible
  // so every componenet has access to any static data generated by the gatsy node files.
  const getComponents = async () => {
    // the components props data is passed in here
    const data = await importPageComponents(pageComponents, {
      ...pageContext,
      businessData,
      configData,
      poweredImages,
      allGeneral,
      pageTitle,
      isMultiLocationSite,
      isAdditionalPage,
    });
    // once the components have been import and the promise has been finished set the components to the state.
    // ready for rendering
    setComponents(data);
  };

  // ?============================================
  // ?====== Dynamic Font Loading Operators ======
  // ?============================================

  const googleFamilies = themeContext.fonts.googleFonts
    ? themeContext.fonts.googleFonts
    : ['Roboto']; // without passing anything it breaks

  const customFamilies = themeContext.fonts.customFamilies
    ? themeContext.fonts.customFamilies
    : [''];
  const customUrls = themeContext.fonts.customUrls
    ? themeContext.fonts.customUrls
    : [''];

  // ?===================================================
  // ?==============   ON/OFF MOUNTING   ================
  // ?===================================================

  useEffect(() => {
    dispatch({
      type: 'SET_SITE_DATA',
      data: {
        ...pageContext,
        businessData,
        configData,
        poweredImages,
        allGeneral,
        pageTitle,
        isMultiLocationSite,
        isAdditionalPage,
      },
    });

    getComponents(); // on load of the template/page get the components

    if (typeof window !== `undefined`) {
      const WebFont = require('webfontloader'); // uses the webfont loadder library to dynamically load the right fonts
      WebFont.load({
        google: {
          families: googleFamilies,
        },
        custom: {
          families: customFamilies,
          urls: customUrls,
        },
      });
    }

    return () => {};
  }, [configData]);

  // this use effect is to keep the site out of screen until the whole site has loaded so animations don't run while the page is loading.
  useEffect(() => {
    if (components) {
      setTimeout(() => {
        const pageWrapper = document.getElementById('pageContainer');
        window.scrollTo({ top: 0 });
        pageWrapper.style.paddingTop = '0rem';
        pageWrapper.style.opacity = '1';
      }, 1500);
    }
    return () => {};
  }, [components]);

  // ?=========================
  // ?===== JSX rendering =====
  // ?=========================

  return (
    <Flex sx={{ flexDirection: ['column', 'column', 'column', 'row'] }}>
      <SideNavLayout
        {...pageContext}
        navLinks={checkForCustomLinks(configData)}
        businessData={businessData}
        configData={configData}
        isMultiLocationSite={locations.length > 1}
        location={location}
        isAdditionalPage={isAdditionalPage}
      >
        <SEO
          title={
            metaTitle
              ? `${metaTitle} | ${capitalizeFirstLetter(pageTitle)} | ${city}`
              : `${name} | ${capitalizeFirstLetter(pageTitle)} | ${city}`
          }
          description={metaDescription ? `${metaDescription}` : `${desc}`}
          keywords={tags ? `${tags}` : `${[]}`}
          favicon={avatar.imageBaseUrl + '/' + avatar.imagePrefix}
          pageTitle={pageTitle}
          city={city}
        />
        <Flex
          sx={{
            flexDirection: 'column',
            paddingTop: '150vh', // prevents section loading ine one go and stop animations from getting loaded and is reset to 0 using a useeffect once everything has loaded.
            opacity: '0',
          }}
          className='pageContainer'
          id='pageContainer'
        >
          {components ? (
            components.map((Component) => {
              return <Component key={shortid.generate()} />;
            })
          ) : (
            <OpeningPage />
          )}

          {/* 
        // * A Contact Form Has to Be Statically imported and Cannot By Dyanmically 
        // *as netlify will not pick up the forms - To add Additional Forms Shoadow ContactFormsContainer 
        */}
          <ContactFormsContainer
            {...pageContext}
            businessData={businessData}
            configData={configData}
            pageTitle={pageTitle}
            poweredImages={poweredImages}
            isMultiLocationSite={isMultiLocationSite}
            variantName={configData.contactForm.variantName}
            options={configData.contactForm.options}
            isAdditionalPage={isAdditionalPage}
          />
        </Flex>
      </SideNavLayout>
    </Flex>
  );
};

export default SideNavPageTemplateV2;

// ?=====================================
// ?======= GraphQl page queries ========
// ?=====================================

export const query = graphql`
  query sideNavPageTemplateQuery($gonationID: String, $locationIndex: Int) {
    businessData(gonationID: { eq: $gonationID }) {
      ...businessDataFields
    }

    poweredImages {
      ...poweredImagesData
    }

    allConfigData {
      edges {
        node {
          ...allConfigDataFields
        }
      }
    }
    allGeneral(filter: { locationIndex: { eq: $locationIndex } }) {
      edges {
        node {
          ...generalArticleData
        }
      }
    }
  }
`;

// businessData(city: { eq: $city }) {
//   ...businessDataFields
// }

// poweredImages {
//   arrayOfImages
//   arrayOfImageIds
//   imageObjects {
//     poweredId
//     imageUrl
//     caption
//   }
// }

// allConfigData {
//   edges {
//     node {
//       ...allConfigDataFields
//     }
//   }
// }
